<template>
  <g :fill="color" fill-rule="nonzero">
    <path
      d="M11.5 12.03c-2.586 0-5.37-.973-8.05-2.812C1.447 7.843.21 6.481.16 6.424a.607.607 0 0 1 0-.819c.051-.057 1.288-1.42 3.291-2.794C6.13.972 8.914 0 11.5 0c2.586 0 5.37.972 8.05 2.811 2.003 1.375 3.24 2.737 3.291 2.794a.607.607 0 0 1 0 .819c-.051.057-1.288 1.42-3.291 2.794-2.68 1.84-5.464 2.811-8.05 2.811zM1.542 6.014c.5.49 1.444 1.351 2.69 2.203C5.94 9.386 8.588 10.78 11.5 10.78s5.56-1.393 7.268-2.56a20.327 20.327 0 0 0 2.69-2.204c-.5-.49-1.444-1.352-2.69-2.204-1.708-1.168-4.356-2.56-7.268-2.56S5.94 2.642 4.232 3.81a20.319 20.319 0 0 0-2.69 2.204z"
    />
    <path
      d="M11.024 9.497c-2.002 0-3.631-1.59-3.631-3.546 0-1.955 1.629-3.545 3.631-3.545.881 0 1.73.312 2.393.878.243.208.267.569.054.807a.596.596 0 0 1-.826.053 2.493 2.493 0 0 0-1.62-.594c-1.357 0-2.46 1.077-2.46 2.401 0 1.325 1.103 2.402 2.46 2.402 1.356 0 2.46-1.077 2.46-2.402 0-.315.262-.572.585-.572.324 0 .586.257.586.572 0 1.955-1.63 3.546-3.632 3.546z"
    />
    <path
      d="M11.09 7.218c-.68 0-1.233-.54-1.233-1.203 0-.664.553-1.203 1.232-1.203.68 0 1.232.54 1.232 1.203s-.552 1.203-1.232 1.203z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
